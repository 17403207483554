
export const Chip: React.FC<{
    title: string;
    icon: string;
    link:string;
    childClass:string;
}> = ({ title, icon, link, childClass }) => (
    <a target="_blank" rel="noopener noreferrer" className={`chip ${childClass}`} href={link}>
        <img src={icon} alt={title} />
        <text style={{ color: "white", flex:1, alignSelf:"center", justifySelf:"center", textAlign:"center" }}>{title}</text>
    </a>
);

export const ChipSameTab: React.FC<{
    title: string;
    icon: string;
    link:string;
    childClass:string;
}> = ({ title, icon, link, childClass }) => (
    <a className={`chip ${childClass}`} href={link}>
        <img src={icon} alt={title} />
        <text style={{ color: "white", flex:1, alignSelf:"center", justifySelf:"center", textAlign:"center" }}>{title}</text>
    </a>
);
