
export const Section: React.FC<{
    children: any;
    title: string;
}> = ({ children, title }) => (
    <div className="Container-Section">
        <text className="Section-Title">{title}</text>
        {children}
    </div>
);
