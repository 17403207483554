import { Chip, ChipSameTab } from '../utils/chip';
import { Section } from '../utils/section';
import './home.css';
export function Home() {
    return (
        <div className='Home'>
            <div className='Home-Header'>
                <img src="/profile.jpeg" alt="profile" className='Profile-Image' />
                <text className='Title-Text'>Abdullah Zowad Khan</text>
                <text className='Title-Paragraph'>
                    Software Developer, Gamer, Geek.
                    <br></br>A main course of android, with added python. Top that with
                    C/C++, a bit of C#, then some NodeJS, and there you have it!
                    <br></br>
                    Currently working as Senior Software Developer at <a target="_blank" rel="noopener noreferrer"
                        href='https://brainstation-23.com/'>
                        Brain Station 23
                    </a>.
                </text>

                <div className='Chip-Row' style={{ marginTop: "10px" }}>
                    <ChipSameTab title='Download CV' icon='/download-file-round-icon.png' link='/cv-abdullah-z-khan.pdf' childClass='cv'></ChipSameTab>
                </div>

                <div className='Chip-Row'>
                    <Chip title='khanabdullahzowad@gmail.com' icon='/gmail.png' link='mailto:khanabdullahzowad@gmail.com' childClass='gmail'></Chip>
                    <Chip title='LinkedIn/abdullah-zowad-khan' icon='/linkedin-4-512.png' link='https://www.linkedin.com/in/abdullah-zowad-khan/' childClass='linkedin'></Chip>
                </div>

                <div className='Chip-Row'>
                    <Chip title='StackOverflow/abdullah-z-khan' icon='/stackoverflow-4-512.png' link='https://stackoverflow.com/users/8190327/abdullah-z-khan' childClass='soverflow'></Chip>
                    <Chip title='GitHub/AZKZero' icon='/github-9-512.png' link='https://github.com/AZKZero' childClass='github'></Chip>
                </div>
            </div>
            <Section title="Professional Experiences">
                <ul className='List-Parent'>
                    <li>
                        <b>
                            Senior Software Developer @{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://brainstation-23.com/">
                                Brain Station 23
                            </a>{" "}
                            (2023-Present)
                        </b>&emsp;(Android, Java, Kotlin)
                        <br></br>
                        Serving Enterprises and SMEs with Technological Partnership Since 2006. Maintainted and developed features for <a href="https://play.google.com/store/apps/details?id=com.arena.banglalinkmela.app">MyBL (My Banglalink)</a> android application, with over 10M+ users.
                    </li>
                    <li>
                        <b>
                            Lead Software Developer @{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.reddotdigitalit.com/">
                                Red.Digital Ltd
                            </a>{" "}
                            (2022-2023)
                        </b>&emsp;(Android, Java, Kotlin)
                        <br></br>
                        Red.Digital Limited is a 100% Robi Axiata Limited owned subsidiary offering IT & Digital solutions. Maintainted and developed features for <a href="https://play.google.com/store/apps/details?id=net.omobio.robisc">My Robi Android</a>, <a href="https://play.google.com/store/apps/details?id=net.omobio.airtelsc">My Airtel Android</a> applications, each with over 10M+ users.
                    </li>
                    <li>
                        <b>
                            Android Developer @{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.technext.it/">
                                Technext Ltd
                            </a>{" "}
                            (2021-2022)
                        </b>&emsp;(Android, Java, Kotlin, NestJS, Python, Flutter)
                        <br></br>
                        Technext offers a faster, more cost-effective, and better way to build your development team and your dream products. I worked as the developer of the <a href="https://play.google.com/store/apps/details?id=com.technextit.starfishvpn.prod">Starfish
                            VPN</a> application, a freemium android application which provided a fast and smooth VPN experience with 10+ servers as a starting point.
                    </li>
                    <li>
                        <b>
                            Lead Software Developer @{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.admissionassistant.com.bd/">
                                Admission Assistant Ltd
                            </a>{" "}
                            (2018-2021)
                        </b>&emsp;(Android, NodeJS)
                        <br></br>A platform providing all the necessary services for university admission on one place. I was one of the founding developers of the start-up, and was responsible for the
                        development and maintenance of the platform from its infancy to its current stage. The <a href="https://play.google.com/store/apps/details?id=com.esp.cattronics.vaa">Admission Assistant</a> android app reached 100K+ users at the time of
                        writing.
                    </li>
                    <li>
                        <b>
                            Junior Embedded Systems Engineer @{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://rumytechnologies.com/">
                                Rumy Technologies Ltd
                            </a>{" "}
                            (2017-2019)
                        </b>&emsp;(Android, C++, C#, ESP, Python)
                        <br></br>
                        Stellar is a small but sound technical team in Chittagong, Bangladesh with expertise in designing electronic product - hardware and firmware, full stack web and
                        mobile application development. I developed and maintained features of biometric attendance devices, and published android applications designed to manage the
                        platform.
                        <br></br>
                        Play Store Links:
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.rumytechnologies.gpsattendance">
                            Stellar GPS Attendance
                        </a>
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.stellar.webapp">
                            Stellar Admin
                        </a>
                    </li>
                </ul>
            </Section>
            <Section title="Side Projects">
                <ul className='List-Parent'>
                    <li>
                        <b>Corona Info Android App</b>
                        <br></br>
                        An Android application to collect responses from users based on questionnaire and contribute to the COVID-19 pandemic response.
                        (Approved by the DGHS)
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=p4KWRZusWrw&ab_channel=ATNNews">
                            News Coverage
                        </a>

                    </li>
                    <li>
                        <b>Hotel Chain</b>
                        <br></br>
                        Hotel Chain was a platform that allowed users to book rooms at their favorite hotels in Bangladesh. It featured online booking and
                        available rooms with details of the rooms and hotels. I worked on it as a freelance android developer.
                        <br></br>
                        Play Store Link: (
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.ngsoftworks.hotelchain">
                            Hotel Chain
                        </a>
                        )

                    </li>
                </ul>
            </Section>
            <Section title="Skills & Expertise">
                <ul className='List-Parent'>
                    <li>

                        <b>Progamming Languages</b>
                        <br></br>
                        Java, Kotlin, C/C++, Python C#, JavaScript, TypeScript, Dart

                    </li>
                    <li>

                        <b>Android</b>
                        <br></br>
                        Native, React Native, Flutter, JetPack Compose, Material Design, Firebase, Room, Google Maps, Mapbox, MQTT, Dagger, Retrofit, AdMob

                    </li>
                    <li>

                        <b>Flutter</b>
                        <br></br>
                        GetX, Drift, Riverpod

                    </li>
                    <li>

                        <b>React Native</b>
                        <br></br>
                        Sequelize, TypeORM, axios, Hooks

                    </li>
                    <li>

                        <b>Embedded Systems</b>
                        <br></br>
                        Arduino, ESP8266, ESP32, PIC

                    </li>
                    <li>

                        <b>Web Development</b>
                        <br></br>
                        React, NodeJS, NestJS, Flask

                    </li>
                </ul>
            </Section>
            <Section title="Publications">
                <ul className='List-Parent'>
                    <li>
                        <b>Graphene-Based Circular Patch Terahertz Antenna Using Novel Substrate Materials (ICIEV-2017)</b>
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1109/ICCITECHN.2018.8631954">
                            [https://doi.org/10.1109/ICCITECHN.2018.8631954]
                        </a>

                    </li>
                    <li>

                        <b>
                            A Portable and Less Time-Consuming Wireless Biometric Attendance System for Academic Purpose Using NodeMCU Microcontroller
                            (ICCIT-2018)
                        </b>
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1109/ICIEV.2017.8338605">
                            [https://doi.org/10.1109/ICIEV.2017.8338605]
                        </a>

                    </li>
                </ul>
            </Section>
        </div>
    );
}

export default Home;
